import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal.component';
import Text from './text.component';
import Loading from './Loading.component';
import { makeStyles, Box } from '@material-ui/core';
import { default as IconButton } from '@material-ui/core/IconButton';
import Button from '../../components/common/button.component';
import { default as SuccessIcon } from '../../assets/icons/success_icon.svg';
import { default as ErrorIcon } from '../../assets/icons/failure_icon.svg';
import { default as CloseButton } from '../../assets/icons/close_icon.svg';

const DEFAULT_MESSAGE = 'Ya casi estamos';
const DEFAULT_LEYEND = 'Estamos procesando los cambios. ';

const useStyles = makeStyles((theme) => ({
  container: {
    /* padding: theme.spacing(4), */
    padding: '35px',
    backgroundColor: theme.palette.grayScale.g0,
    borderRadius: theme.borderRadius.main,
    height: 'max-content',
    width: '39rem',
    [theme.breakpoints.down('md')]: {
      width: '35vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '60vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '80vw',
    },
  },
  containerError_Success: {
    padding: '2rem 3.438rem',
    backgroundColor: theme.palette.grayScale.g0,
    borderRadius: theme.borderRadius.main,
    position: 'relative',
    justifyContent: 'center',
    width: '39rem',
    [theme.breakpoints.down('md')]: {
      width: '35vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '60vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '80vw',
      padding: '2rem 0.438rem',
    },
  },
  loading: {
    marginBottom:'1rem',
    marginTop:'3.375rem'
  },
  error: {
    marginTop: '3rem',
  },
  success: {
    marginTop: '3rem',
    marginBottom:'1.75rem'
  },
  message: {
    lineHeight: '1.8rem',
    fontSize: '1.2rem',
    textAlign: 'center',
    marginBottom: '1.5rem'
  },
  messageLoading: {
    lineHeight: '1.8rem',
    fontSize: '1.2rem',
    textAlign: 'center',
  },
  leyend: {
    fontSize: '1.2rem',
    textAlign: 'center',
    marginBottom: '50px',
    marginTop: '10px',
    fontWeight: '500',
    width: '400px',
    paddingBottom: '60px',
  },
  leyendGrey: {
    fontSize: '1.125rem',
    textAlign: 'center',
    marginBottom: '3.375rem',
    marginTop: '1rem',
    fontWeight: '400',
    width: '400px',
    paddingBottom: '3.125rem',
    color: theme.palette.grayScale.g400,
  },
  icon_error: {
    marginBottom:'0.9rem',
  },
  messageError: {
    lineHeight: '1.8rem',
    fontSize: '1.2rem',
    textAlign: 'center',
  },
  messageError2: {
    lineHeight: '1.8rem',
    fontSize: '1.2rem',
    textAlign: 'center',
    marginBottom: '1rem'
  },
  leyendGreyError: {
    fontSize: '1.125rem',
    textAlign: 'center',
    marginTop: '1rem',
    marginBottom: '2.8rem',
    fontWeight: '400',
    width: '400px',
    color: theme.palette.grayScale.g400,
  },
  leyendError_Success: {
    width:'14.563rem',
    height: '3rem',
    fontSize: '1rem',
    textAlign: 'center',
    padding: '0.75rem 2rem',
    lineHeight: '24px',
    marginBottom:'3.375rem'
  },
  close_button: {
    float: 'right',
    border: '0px',
    position: 'absolute',
    right: '10px',
    top: '10px',
    '&:hover, &:focus, &:click': {
      backgroundColor: 'transparent',
    },
  },
  sectLeyend:{
    width:'26.25rem',
    maxWidth: '100%',
    height:'8.438rem',
    display: 'flex',
    flexDirection:'column',
    alignItems:'center',
    textAlign:'center',
    lineHeight:'1.688rem',
    paddingTop: '2rem'
  },
  centeredLeyend:{
    display:'flex',
    justifyContent:'center'
  }
}));

const LoadingModal = ({
  updating,
  updatedSuccess,
  updatedError,
  open,
  message,
  leyend,
  errorMessage,
  errorMessage2,
  errorLeyendPrimary,
  errorLeyendSecondary,
  btnTxt,
  btnTxtOk,
  okMessage,
  okLeyend,
  onClose,
  handleCloseModalClick,
  customVisibility,
  succesContainerClass,
  errorContainerClass,
  loadingContainerClass,
  sectionLeyendPrimary,
  sectionLeyendSecondary,
  modalSubtitleStyle,
  ...modalProps
}) => {
  return (
    <Modal open={open} onClose={onClose} disableBackdropClick {...modalProps}>
      {(!customVisibility || (customVisibility && updating)) && (
        <LoadingMessage message={message} leyend={leyend} updating={updating} adicionalContainerClass={succesContainerClass} modalSubtitleStyle={modalSubtitleStyle} />
      )}
      {updatedSuccess === true && !updating && (
        <SuccessMessage 
          handleCloseModalClick={handleCloseModalClick} 
          adicionalContainerClass={succesContainerClass} 
          btnTxt={btnTxtOk} 
          message={okMessage} 
          leyendPrimary={okLeyend} 
        />
      )}
      {updatedSuccess === false && !updating && (
        <ErrorMessage
          message={errorMessage}
          message2={errorMessage2}
          leyendPrimary={errorLeyendPrimary}
          leyendSecondary={errorLeyendSecondary}
          btnTxt={btnTxt}
          handleCloseModalClick={handleCloseModalClick}
          adicionalContainerClass={errorContainerClass}
          sectionLeyendPrimary={sectionLeyendPrimary}
          sectionLeyendSecondary={sectionLeyendSecondary}
        />
      )}
    </Modal>
  );
};

export const LoadingMessage = ({ message, leyend, updating, adicionalContainerClass, modalSubtitleStyle }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyItems="start"
      className={`${classes.container} ${adicionalContainerClass}`}
    >
      <Box className={classes.loading}>
        <Loading />
      </Box>
      <Text bold className={classes.messageLoading}>
        {message}
      </Text>
      <Text className={`${modalSubtitleStyle} ${updating ? classes.leyendGrey : classes.leyend}`}>
        {updating ? leyend : 'Cargando...'}
      </Text>
    </Box>
  );
};

export const ErrorMessage = ({
  message,
  message2,
  leyendPrimary,
  leyendSecondary,
  btnTxt,
  handleCloseModalClick,
  adicionalContainerClass,
  sectionLeyendPrimary,
  sectionLeyendSecondary
}) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={`${classes.containerError_Success} ${adicionalContainerClass}`}
    >
      <Box className={classes.error}>
        <ErrorIcon className={classes.icon_error} />
      </Box>
      <IconButton
        className={classes.close_button}
        onClick={() => {
          handleCloseModalClick()
        }}
      >
        <CloseButton />
      </IconButton>
      <Text
        bold
        className={`${classes.messageError}`}
      >
        {message ? message : 'Los cambios no se pudieron guardar.'}
      </Text>
      {message2 && (
        <Text
          bold
          className={`${classes.messageError2}`}
        >
          {message2}
        </Text>
      )}
      <div className={classes.sectLeyend}>
        {leyendPrimary && (
          <div className={`${sectionLeyendPrimary}`}>
            <Text bold className={classes.leyendGreyError}>
              <span>{leyendPrimary}</span>
            </Text>
          </div>
        )}
        {leyendSecondary && (
          <div className={`${sectionLeyendSecondary}`}>
            <Text bold className={classes.leyendGreyError}>
              <span className={classes.centeredLeyend}>{leyendSecondary}</span>
            </Text>
          </div>
        )}
      </div>
      <Button
        primary
        onClick={() => handleCloseModalClick()}
        className={classes.leyendError_Success}
      >
        {btnTxt ? btnTxt : 'Volver'}
      </Button>
    </Box>
  )
};

export const SuccessMessage = ({ 
  handleCloseModalClick,
  adicionalContainerClass = '',
  btnTxt = 'Continuar',
  message = 'Los cambios fueron guardados con exito',
  leyendPrimary = ''
}) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={`${classes.containerError_Success} ${adicionalContainerClass}`}
    >
      <IconButton
        className={classes.close_button}
        onClick={() => handleCloseModalClick()}
      >
        <CloseButton />
      </IconButton>

      <Box className={classes.success}>
        <SuccessIcon />
      </Box>
      <Text bold className={classes.message}>
        {message}
      </Text>
      {leyendPrimary && (<Text className={classes.leyendGreyError}>{leyendPrimary}</Text>)}
      <Button
        primary
        onClick={() => handleCloseModalClick()}
        className={classes.leyendError_Success}
      >
        {btnTxt}
      </Button>
    </Box>
  );
};

LoadingModal.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  errorMessage2: PropTypes.string,
  leyend: PropTypes.string,
  onClose: PropTypes.func,
};

LoadingModal.defaultProps = {
  open: true,
  message: DEFAULT_MESSAGE,
  leyend: DEFAULT_LEYEND,
  onClose: () => {},
};

LoadingMessage.defaultProps = {
  message: DEFAULT_MESSAGE,
  leyend: DEFAULT_LEYEND,
};

export default LoadingModal;
